
















































































































































import { mapGetters, mapActions } from "vuex"
import { debounce } from "lodash"

import ChatWrap from "@/components/Chat/ChatWrap.vue"
import ChatHead from "@/components/Chat/ChatHead.vue"
import Messages from "@/components/Chat/Messages.vue"
import { RtbSpinner } from "@/components/ui"
import ChatContextToggle from "./ChatContextToggle.vue"
import AnonymousToggle from "./AnonymousToggle.vue"

import { ALL_CHAT_CONTEXT, CHAT_CONTEXTS } from "./constants"

import { Draggable } from "draggable-vue-directive"

const DEFAULT_BOUNDING_RECT_MARGIN = {
  top: 50,
  bottom: 70,
  left: 10,
  right: 70
}

const CHAT_HEIGHT = 494
const CHAT_WIDTH = 350

export default ChatWrap.extend({
  name: "GameChatWrap",
  directives: { Draggable },
  components: {
    ChatHead,
    Messages,
    RtbSpinner,
    ChatContextToggle,
    AnonymousToggle
  },
  props: {
    dropRight: {
      type: Boolean,
      default: false
    },
    dropBottom: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: "left",
      validator: str => ["left", "right"].includes(str)
    }
  },
  data() {
    return {
      isChatVisible: false,
      message: null,
      draggableValue: {
        resetInitialPos: true,
        handle: undefined,
        boundingElement: document.body,
        boundingRectMargin: DEFAULT_BOUNDING_RECT_MARGIN
      },
      buttonPosition: {
        top: null,
        left: null
      },
      buttonHeight: null,
      buttonWidth: null,
      yDirection: null,
      xDirection: null
    }
  },
  computed: {
    ...mapGetters(["getTeamDetail", "orgID", "gameID"]),
    ...mapGetters("auth", [
      "clientID",
      "user",
      "isHost",
      "hasPreGame",
      "isModerator",
      "client",
      "isSuper"
    ]),
    screenSize() {
      return {
        width: CHAT_WIDTH,
        height: CHAT_HEIGHT
      }
    },
    windowPosition() {
      const { yDirection, xDirection } = this
      return [yDirection, xDirection].map(
        direction => `game-chat__body--${direction}`
      )
    },
    xPos() {
      if (window.innerWidth / 2 > this.buttonPosition.left) {
        return "left"
      }
      return "right"
    },
    yPos() {
      const offsetTop = DEFAULT_BOUNDING_RECT_MARGIN.top
      const boundingTop =
        this.buttonPosition.top + this.buttonHeight - offsetTop

      if (CHAT_HEIGHT >= boundingTop) {
        return "bottom"
      }

      return "top"
    },
    canToggleAnonymousChat() {
      return (
        [CHAT_CONTEXTS.GAME, CHAT_CONTEXTS.TEAM].includes(
          this.getChatContext
        ) && this.isHost
      )
    }
  },
  watch: {
    isChatVisible() {
      this.updateHandle()

      this.yDirection = this.yPos
      this.xDirection = this.xPos

      this.updateBoundingMargin()
    },
    availableChatOptionsWatchValue: {
      handler: debounce(function (newContexts, oldContexts) {
        this.onWatchAvailableChatOptionsWatchValue(
          newContexts,
          oldContexts,
          ALL_CHAT_CONTEXT
        )
      }, 500),
      immediate: true
    },
    getChatContext: {
      handler: debounce(function (newContexts, oldContexts) {
        this.onWatchGetChatContext(newContexts, oldContexts, ALL_CHAT_CONTEXT)
      }, 500),
      immediate: true
    },
    isModerator(nextVal, prevVal) {
      // if (nextVal && nextVal !== prevVal) {
      // } else {
      // }
      this.adjustPosition(nextVal)
    }
  },
  mounted() {
    this.draggableValue.onPositionChange = this.positionChange
    this.draggableValue.handle = this.$refs.chatButton.$refs.handle

    const { top, left } = this.$refs.chatWrapper.getBoundingClientRect()
    this.buttonPosition.top = top
    this.buttonPosition.left = left

    this.$refs.chatWrapper.draggable = false

    this.buttonHeight = this.$refs.chatButton.$el.offsetHeight
    this.buttonWidth = this.$refs.chatButton.$el.offsetWidth
    window.addEventListener("resize", this.onWindowResize)
    this.setLastWindowSize()
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize)
  },
  methods: {
    ...mapActions("Games", ["updateGameAny"]),
    async onSendMessage(e) {
      if (!e.shiftKey) {
        e.preventDefault()
        const { chatComponent } = this.$refs
        if (chatComponent) {
          await chatComponent.onEnterMessage(
            e,
            this.message,
            this.game?.anonymousChat
          )
        }
        this.message = ""
      }
    },
    positionChange(positionDiff, absolutePosition, event) {
      const { top, left } = absolutePosition

      if (top && left && this.buttonPosition) {
        this.buttonPosition.top = top
        this.buttonPosition.left = left
      }

      if (this.draggableValue.initialPosition) {
        this.draggableValue.initialPosition.top = top
        this.draggableValue.initialPosition.left = left
      }

      if (positionDiff.x || positionDiff.y) {
        this.setLastWindowSize()
      }
    },
    updateHandle() {
      if (this.isChatVisible) {
        this.$nextTick(() => {
          this.draggableValue.handle = this.$refs.headerHandler
          this.$forceUpdate()
        })
      } else {
        const handle = this.$refs.chatButton.$refs.handle
        this.$nextTick(() => {
          const draggableState = JSON.parse(
            handle.getAttribute("draggable-state")
          )
          draggableState.startDragPosition = this.buttonPosition
          draggableState.currentDragPosition = this.buttonPosition
          this.draggableValue.handle = handle
          this.$forceUpdate()
          handle.setAttribute("draggable-state", JSON.stringify(draggableState))
        })
      }
    },
    updateBoundingMargin() {
      if (this.isChatVisible) {
        const { top, bottom, left, right } = DEFAULT_BOUNDING_RECT_MARGIN
        const screenOffsetTop = CHAT_HEIGHT - this.buttonHeight + top + 10
        const screenOffsetBottom = CHAT_HEIGHT - this.buttonHeight + bottom + 10
        const screenOffsetLeft = CHAT_WIDTH - this.buttonWidth + left + 10
        const screenOffsetRight = CHAT_WIDTH - this.buttonWidth + right + 10
        this.draggableValue.boundingRectMargin = {
          top: this.yDirection === "top" ? screenOffsetTop : top,
          bottom: this.yDirection === "top" ? bottom : screenOffsetBottom,
          left: this.xDirection === "right" ? screenOffsetLeft : left,
          right: this.xDirection === "right" ? right : screenOffsetRight
        }
      } else {
        this.draggableValue.boundingRectMargin = DEFAULT_BOUNDING_RECT_MARGIN
      }
    },
    toggleAnonymousChat() {
      const { id, anonymousChat } = this.game
      const gameUpdate = {
        theKey: id,
        anonymousChat: !anonymousChat
      }
      this.updateGameAny(gameUpdate)
    },
    syncPosition() {
      const draggableState = JSON.parse(
        this.$refs.chatButton.$el.getAttribute("draggable-state")
      )

      if (!draggableState) return

      draggableState.startDragPosition = this.buttonPosition
      draggableState.currentDragPosition = this.buttonPosition

      this.draggableValue.handle = this.$refs.chatButton
      this.$forceUpdate()
      this.$refs.chatButton.$el.setAttribute(
        "draggable-state",
        JSON.stringify(draggableState)
      )
    },
    setLastWindowSize() {
      this.lastWindowSize = {
        x: window.innerWidth,
        y: window.innerHeight
      }
    },
    adjustPosition(value) {
      setTimeout(() => {
        const modifier = value ? -1 : 1
        this.draggableValue.initialPosition.top += 50 * modifier
        this.$forceUpdate()
      }, 500)
    },
    onWindowResize: debounce(function () {
      if (!this.lastWindowSize) return 0

      const { top, left } = this.buttonPosition
      const { x, y } = this.lastWindowSize

      this.draggableValue.initialPosition = {
        top: top - (y - window.innerHeight),
        left: left - (x - window.innerWidth)
      }

      this.$forceUpdate()
      this.setLastWindowSize()
    }, 250)
  }
})
