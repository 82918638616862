<template>
  <div class="anonymous-chat-toggle">
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn
          v-on="on"
          small
          fab
          @click="$emit('toggleAnonymousChat')"
          :class="{ 'anonymous-chat-toggle--active': status }"
        >
          a
        </v-btn>
      </template>
      <span class="bottom">
        {{ status ? "Turn Off Anonymous Chat" : "Enable Anonymous Chat" }}
      </span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "AnonymousToggle",
  props: {
    status: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss">
.anonymous-chat-toggle {
  position: absolute;
  left: -2px;
  top: 3px;
  .v-btn--floating.v-btn--small {
    height: 20px;
    width: 20px;
  }
  &--active,
  .v-btn--depressed {
    background-color: #8680ff !important;
    color: white;
  }
}
</style>
