var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "draggable",
          rawName: "v-draggable",
          value: _vm.draggableValue,
          expression: "draggableValue",
        },
      ],
      ref: "chatWrapper",
      staticClass: "game-chat",
    },
    [
      _vm.isChatVisible
        ? _c("Chat", {
            ref: "chatComponent",
            staticClass: "game-chat__body",
            class: _vm.windowPosition,
            style: _vm.screenSize,
            attrs: {
              teamID: _vm.getTeamID,
              roomID: _vm.getChatRoomID,
              context: _vm.getChatContext,
              placeholder: _vm.messagePlaceholder,
              canSendMessage: _vm.canSendMessage,
              isAnonymousChat: _vm.isAnonymousChat,
              messageText: _vm.message,
            },
            on: {
              closeChat: function ($event) {
                _vm.isChatVisible = false
              },
              "update:messageText": function ($event) {
                _vm.message = $event
              },
              "update:message-text": function ($event) {
                _vm.message = $event
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "chat-header",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { ref: "chatHeader", staticClass: "game-chat__header" },
                        [
                          _c("div", {
                            ref: "headerHandler",
                            staticClass: "game-chat__header-handler",
                          }),
                          _vm.canToggleAnonymousChat
                            ? _c("AnonymousToggle", {
                                attrs: {
                                  status: Boolean(_vm.game.anonymousChat),
                                },
                                on: {
                                  toggleAnonymousChat: _vm.toggleAnonymousChat,
                                },
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass: "game-chat__header-primary",
                              class: {
                                "game-chat__header-primary--reduced":
                                  _vm.titleDisplayName.reduced,
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.titleDisplayName.primary) + " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "game-chat__header-secondary" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.titleDisplayName.secondary) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.availableChatContext.length > 1
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "game-chat__header-chat-context-toggle",
                            },
                            [
                              _c("ChatContextToggle", {
                                staticStyle: { "margin-top": "14px" },
                                attrs: {
                                  teamID: _vm.getTeamID,
                                  contexts: _vm.availableChatContext,
                                  selectedContext: _vm.getChatContext,
                                },
                                on: {
                                  localContextChange: _vm.onLocalContextChange,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", {
                        staticClass: "game-chat__header-close",
                        on: { click: _vm.hideChat },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "chat-messages",
                  fn: function (ref) {
                    var chat = ref.data
                    return [
                      _c(
                        "v-layout",
                        { staticClass: "game-chat__messages-wrap" },
                        [
                          _c("Messages", {
                            attrs: {
                              messages: chat.messages,
                              teamID: chat.teamID,
                              contextRef: chat.contextRef,
                              canLazyLoadMessage: chat.canLazyLoadMessage,
                              context: chat.context,
                            },
                            on: {
                              LAZYLOAD_MESSAGE: chat.LAZYLOAD_MESSAGE,
                              onSelectedGameIDUpdate:
                                chat.onSelectedGameIDUpdate,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "messages",
                                  fn: function () {
                                    return _vm._l(
                                      chat.messages,
                                      function (data, index) {
                                        return _c(
                                          "v-flex",
                                          {
                                            key: index + "-" + data.key,
                                            staticClass:
                                              "game-chat__message-wrap",
                                            attrs: { "flex-end": "" },
                                          },
                                          [
                                            _c(
                                              "v-layout",
                                              {
                                                staticClass:
                                                  "game-chat__message-wrap--contain",
                                                class:
                                                  _vm.user.id ===
                                                  data.messagePayload.fromID
                                                    ? "right-message"
                                                    : "left-message",
                                                attrs: { column: "" },
                                              },
                                              [
                                                _vm.user.id !==
                                                data.messagePayload.fromID
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "game-chat__message-wrap--contain___time",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.userDetail
                                                                .firstname
                                                            ) +
                                                            " — " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "messageTime"
                                                              )(
                                                                data
                                                                  .messagePayload
                                                                  .timestamp
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c("div", {
                                                  staticClass: "message",
                                                  class: {
                                                    "message--danger":
                                                      _vm.isHost &&
                                                      data.messagePayload.message.includes(
                                                        "@host"
                                                      ),
                                                  },
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.$options.filters.normalizeMessage(
                                                        data.messagePayload
                                                          .message
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    )
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "chat-loader",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "game-chat__chat-loading",
                          class: {
                            "game-chat__chat-loading--increased":
                              _vm.availableChatContext.length > 1,
                          },
                        },
                        [_c("rtb-spinner")],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1062975618
            ),
          })
        : _vm._e(),
      _c("ChatHead", {
        ref: "chatButton",
        attrs: {
          shouldSubscribe: false,
          shouldDisplayUnreadLabel:
            !_vm.isChatVisible && !!_vm.allUnreadMessage,
          customUnreadMessage: _vm.allUnreadMessage,
          teamID: _vm.getTeamID,
          context: _vm.getChatContext,
        },
        nativeOn: {
          click: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
            _vm.isChatVisible = true
          },
        },
        scopedSlots: _vm._u([
          {
            key: "unread",
            fn: function () {
              return [
                _c("div", { staticClass: "game-chat__unread" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "game-chat__unread-label game-chat__unread-label--danger",
                    },
                    [_vm._v(" " + _vm._s(_vm.allUnreadMessage) + " ")]
                  ),
                  _vm.numberUnreadAtHost && _vm.isHost
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "game-chat__unread-label game-chat__unread-label--primary",
                        },
                        [_vm._v(" " + _vm._s(_vm.numberUnreadAtHost) + " ")]
                      )
                    : _vm._e(),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }