var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "anonymous-chat-toggle" },
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        class: { "anonymous-chat-toggle--active": _vm.status },
                        attrs: { small: "", fab: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("toggleAnonymousChat")
                          },
                        },
                      },
                      on
                    ),
                    [_vm._v(" a ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("span", { staticClass: "bottom" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.status
                    ? "Turn Off Anonymous Chat"
                    : "Enable Anonymous Chat"
                ) +
                " "
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }